body {
    background: linear-gradient(90deg, #90a4ae 20%, #607d8b 40%, #37474f 60%, #607d8b 80%, #90a4ae 100%);
    padding: 20px;
    margin: 0;
    display: flex;
    justify-content: center;
}

h1, h2, h3 {
    font-family: sans-serif;
}

h2, h3 {
    color: #2c383c;
}

p, ul, li {
    font-family: sans-serif;
    color: dimgray;
}

.text-container {
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
}

ul.header li {
    display: inline;
    list-style-type: none;
    margin: 0;
}

ul.header {
    display: flex;
    align-items: center;
    background-color: #FFF;
    padding: 0;
    margin-bottom: 10px;
    border-radius: 5px;
}

.drawer {
    display: none;
    width: 200px;
}

.drawer a {
    color: #37474f;
    font-weight: bold;
    text-decoration: none;
    padding: 20px;
    display: inline-block;

    &.active, &:hover {
        color: #90a4ae;
    }
}

ul.header li a {
    color: #37474f;
    font-weight: bold;
    text-decoration: none;
    padding: 20px;
    display: inline-block;

    &.active, &:hover {
        color: #90a4ae;
    }
}

.content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}
.content h2 {
    padding: 0;
    margin: 0;
}
.content li {
    margin-bottom: 10px;
}

.footer {
    position: relative;
    background-color: #FFF;
    padding: 0;
    margin-top: 10px;
    text-align: center;
    border-radius: 5px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.footer p {
    color: #000;
    text-decoration: none;
    padding: 5px;
    display: inline-block;
}

.footer a:hover {
    color: #90a4ae;
}

.footer a {
    color: #000;
    text-decoration: none;
    padding: 5px;
    display: inline-block;
}

.portrait-container {
    max-width: 200px;
    display: flex;
}

.portrait {
    align-self: center;
    border-radius: 360px;
    border-color: dimgray;
    border-width: 2px;
    border-style: solid;
}

.headline-container {
    display: inline-flex;
    gap: 20px;
}

.headline {
    align-self: center;
    color: white;
}

#root {
    width: 70%;
}

.menu-icon {
    color: #000;
    display: none !important;
    margin: 20px;
    cursor: pointer;
}

.menu-icon:hover {
    color: dimgray;
}

.close-icon {
    cursor: pointer;
    align-self: flex-end;
    margin: 20px 20px 0;
    color: #000;
}

.close-icon:hover {
    cursor: pointer;
    color: dimgray;
}

.contact-icon {
    color: dimgray;
    font-size: 50px !important;
}

.contact-container {
    position: absolute;
    right: 0;
    display: flex;
}

@media (max-width: 900px) {
    h1 {
        font-size: 1.7em;
    }

    #root {
        width: 100%;
    }

    .contact-container {
        position: relative;
    }

    .menu-icon {
        display: initial !important;
    }

    ul.header li {
        display: none;
    }

    .drawer {
        display: initial;
    }
}

