.form-container {
    margin: 10px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.config-key-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.config-key {
    font-size: 20px;
}

.config-input {
    width: 100%;
}

.submit-button-container {
    display: flex;
    flex-direction: row;
    align-self: end;
}

.form-checkbox-container {
    display: flex;
    flex-direction: row;
    padding-right: 20px;
}

p {

}

#submit-button {
    font-weight: bold;
}