.expertise-container {
    margin: 8px;
}

.expertise-container-header:hover {
    background-color: #90a4ae;
    color: #2c383c;
}

.expertise-container-header {
    color: #2c383c;
}